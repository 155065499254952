import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import PropTypes from "prop-types"

function EquipmentLink({ equipment }) {
  return (
    <StaticQuery
      query={equipmentLinkQuery}
      render={(data) => {
        const allEquipmentPosts = data.allMarkdownRemark.edges
        const equipmentPost = allEquipmentPosts.find(({ node }) => {
          return node && node.frontmatter && node.frontmatter.title && node.frontmatter.title === equipment
        })
        return equipmentPost ? (
          <Link
            key={equipmentPost.node.fields.slug}
            to={'/equipment' + equipmentPost.node.fields.slug}
          >
            {equipmentPost.node.frontmatter.title}
          </Link>
        ) : equipment
      }}
    />
  )
}

EquipmentLink.propTypes = {
  equipment: PropTypes.string,
}

const equipmentLinkQuery = graphql`
  query EquipmentLinkQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { path: { eq: "equipment"}} }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`

export default EquipmentLink
