import React from "react";
import PropTypes from "prop-types";
import Link from "gatsby-link";

const RecipeCard = props => {
    const { hit } = props;
    return ( hit && hit.fields) ? (
      <Link to={'/recipe' +  hit.fields.slug} >
        { hit.frontmatter.featuredImage && hit.frontmatter.featuredImage.childImageSharp && hit.frontmatter.featuredImage.childImageSharp.fixed &&
          <img srcSet={hit.frontmatter.featuredImage.childImageSharp.fixed.srcSet} />
        }
        <strong>
          {hit.frontmatter.title}
        </strong>
      </Link>
    ): '';
  };
  RecipeCard.propTypes = {
    hit: PropTypes.object.isRequired
  };
  export default RecipeCard;
