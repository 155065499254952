import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { FaUser } from 'react-icons/fa';

import PropTypes from 'prop-types';
import { rhythm, scale } from '../utils/typography';
import Img from 'gatsby-image';

const Source = styled.div`
  border: 1px solid grey;
  min-height: ${scale(1)};
  padding: ${rhythm(0.5)};
  margin-top: ${rhythm(0.5)};
  margin-bottom: ${rhythm(0.5)};
  width: 95%;
  margin-left: 5px;
  margin-right: 5px;

  @media (min-width: 780px) {
    // margin-left: ${rhythm(0.5)};
    // margin-right: ${rhythm(0.5)};
    margin-top: 0;
    flex-basis: 44%;
    flex-grow: 1;
    max-width: 48%;
  }

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  strong {
    margin-left: ${rhythm(0.5)};
  }
  p {
    margin-top: ${rhythm(0.5)};
    margin-bottom: ${rhythm(0.5)};
    line-height: ${rhythm(1)};
  }

  a.chefLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    .gatsby-image-wrapper {
      width: 100px;
      height: 100px;
      border-radius: 50px;
    }
  }
`;

function SourceLink({ chef, dish, link, publication }) {
  return (
    <StaticQuery
      query={sourceLinkQuery}
      render={(data) => {
        const allChefPosts =
          data && data.allMarkdownRemark && data.allMarkdownRemark.edges;
        const chefPost =
          allChefPosts &&
          allChefPosts.find(({ node }) => {
            return (
              node &&
              node.frontmatter &&
              node.frontmatter.title &&
              node.frontmatter.title === chef
            );
          });
        return (
          <Source>
            {chefPost ? (
              <Link
                to={'/chef' + chefPost.node.fields.slug}
                className="chefLink"
              >
                {chefPost.node.frontmatter.image ? (
                  <Img
                    property="image"
                    fluid={
                      chefPost.node.frontmatter.image.childImageSharp.sizes
                    }
                  />
                ) : (
                  <FaUser size="70px" />
                )}
                <strong>{chefPost.node.frontmatter.title}</strong>
              </Link>
            ) : (
              <span>
                <FaUser size="70px" />
                <strong>{chef}</strong>
              </span>
            )}

            <p>
              <a href={link}>
                {dish}
                <br />
                <i>{publication}</i>
              </a>
            </p>
          </Source>
        );
      }}
    />
  );
}

SourceLink.propTypes = {
  chef: PropTypes.string,
  dish: PropTypes.string,
  link: PropTypes.string,
  publication: PropTypes.string,
};

const sourceLinkQuery = graphql`
  query SourceLinkQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { path: { eq: "chef" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                sizes(maxWidth: 100, maxHeight: 100) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default SourceLink;
