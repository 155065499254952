import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';

import PropTypes from 'prop-types';

const formatIngredient = (ingredient) => {
  return `${ingredient.amount ? ingredient.amount + ' ' : ''}${
    ingredient.name
  }${ingredient.preparation ? ', ' + ingredient.preparation : ''}`;
};

function IngredientLink({ ingredient }) {
  return (
    <StaticQuery
      query={ingredientLinkQuery}
      render={(data) => {
        const allIngredientPosts = data.allMarkdownRemark.edges;
        const ingredientPost = allIngredientPosts.find(({ node }) => {
          return (
            node &&
            node.frontmatter &&
            node.frontmatter.title &&
            node.frontmatter.title === ingredient.name
          );
        });
        return ingredientPost ? (
          <Link
            key={ingredientPost.node.fields.slug}
            to={'/ingredient' + ingredientPost.node.fields.slug}
          >
            {formatIngredient(ingredient)}
          </Link>
        ) : (
          formatIngredient(ingredient)
        );
      }}
    />
  );
}

IngredientLink.propTypes = {
  ingredient: PropTypes.object,
};

const ingredientLinkQuery = graphql`
  query IngredientLinkQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { path: { eq: "ingredient" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;

export default IngredientLink;
