import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { InstantSearch, Hits, Configure } from 'react-instantsearch-dom';
import RecipeCard from './recipe-card';
import algoliasearch from 'algoliasearch/lite';

const StyledAlgolia = styled.div`
  .ais-SearchBox {
    display: none !important;
  }

  .ais-Hits-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    @media only screen and (min-width: 600px) {
      flex-direction: row;
    }
  }

  .ais-Hits-item {
    display: block;
    margin: 5px;
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 33%;
    @media only screen and (min-width: 600px) {
      min-width: 200px;
    }
    img {
      margin-bottom: -7px;
    }
    strong {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 5px;
      width: 100%;
      color: white;
      background-color: rgba(0, 0, 0, 0.5);
      margin-bottom: 0;
      box-sizing: border-box;
    }
  }
`;

function SimilarRecipes({ currentSlug, tags, keywords, cuisine, category }) {
  const filters = currentSlug
    ? `NOT fields.slug:"${currentSlug.toString()}"`
    : '';
  return (
    <StaticQuery
      query={similarRecipesQuery}
      render={(data) => {
        console.log(currentSlug, filters);
        const algolia = data.site.siteMetadata.algolia;
        const searchClient =
          algolia &&
          algolia.appId &&
          algoliasearch(algolia.appId, algolia.searchOnlyApiKey);
        const algoliaString = `${
          tags ? tags.join(' ') : ''
        } ${keywords} ${cuisine} ${category}`.replace(/\,/g, ' ');
        return (
          <StyledAlgolia>
            {algolia && algolia.appId && (
              <InstantSearch
                indexName={algolia.indexName}
                searchClient={searchClient}
              >
                <Configure
                  query={algoliaString}
                  hitsPerPage={3}
                  analytics={true}
                  filters={filters}
                  removeWordsIfNoResults="allOptional"
                  distinct
                />
                <Hits hitComponent={RecipeCard} />
              </InstantSearch>
            )}
          </StyledAlgolia>
        );
      }}
    />
  );
}

SimilarRecipes.propTypes = {
  currentSlug: PropTypes.string.isRequired,
  tags: PropTypes.array,
  keywords: PropTypes.string,
  cuisine: PropTypes.string,
  category: PropTypes.string,
};

export default SimilarRecipes;

export const similarRecipesQuery = graphql`
  query SimilarRecipesQuery {
    site {
      siteMetadata {
        algolia {
          appId
          searchOnlyApiKey
          indexName
        }
      }
    }
  }
`;
