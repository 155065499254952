import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import EquipmentLink from '../components/equipment-link';
import IngredientLink from '../components/ingredient-link';
import SourceLink from '../components/source-link';
import Pluralize from 'pluralize';

import { rhythm, scale } from '../utils/typography';
import { FaClock, FaUserClock, FaUtensils, FaStopwatch } from 'react-icons/fa';
import SimilarRecipes from '../components/similar-recipes';

const RecipePost = styled.div`
  padding-left: ${rhythm(1 / 2)};
  padding-right: ${rhythm(1 / 2)};
  max-width: 780px;
  text-align: center;
`;

const RecipeOverview = styled.div`
  border-top: 5px solid #000;
  border-bottom: 5px solid #000;
  padding-top: ${rhythm(1)};
  padding-bottom: ${rhythm(1)};
  list-style-type: disc;
  padding-left: ${rhythm(1.5)};
  width: 100%;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      margin-left: ${rhythm(0.5)};
    }
  }

  @media only screen and (min-width: 600px) {
    padding-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
`;

const RecipeIngredients = styled.div`
  padding: ${rhythm(0.5)};
  width: 100%;
  max-width: 700px;
  border-bottom: 5px solid #000;
  padding-bottom: ${rhythm(1)};

  ul {
    padding-left: ${rhythm(0.5)};
    @media only screen and (min-width: 1280px) {
      padding: 0;
    }
  }

  li {
    list-style-type: none;
  }

  li:before {
    content: '\00bb';
    color: #ff4e4e;
    padding-right: ${rhythm(1 / 4)};
  }
`;

const RecipeEquipment = styled.div`
  padding: ${rhythm(0.5)};
  width: 100%;
  max-width: 700px;
  padding-bottom: ${rhythm(1)};
  ul {
    padding-left: ${rhythm(0.5)};
    @media only screen and (min-width: 1280px) {
      padding: 0;
    }
  }

  li {
    list-style-type: none;
  }

  li:before {
    content: '\00bb';
    color: #ff4e4e;
    padding-right: ${rhythm(1 / 4)};
  }
`;

const RecipeInstructions = styled.div`
  border-top: 5px solid #000;
  padding: ${rhythm(0.5)};
  padding-top: ${rhythm(1)};
  border-bottom: 5px solid #000;
  padding-bottom: ${rhythm(1)};
  ol {
    padding-left: ${rhythm(0.5)};
  }

  li {
    position: relative;
    counter-increment: step-counter;
    list-style-type: none;
    margin-bottom: ${rhythm(1)};

    .gatsby-image-wrapper {
      margin-top: ${rhythm(0.5)};
    }
    label {
      height: ${rhythm(1)};
      display: block;
      font-weight: bold;
    }
  }

  li:before {
    content: counter(step-counter);
    margin-right: 0;
    background-color: #ff4e4e;
    color: #fff;
    font-size: ${rhythm(0.5)};
    position: absolute;
    left: ${rhythm(-1.5)};
    padding-top: 2px;
    width: ${rhythm(1)};
    height: ${rhythm(1)};
    text-align: center;
  }
`;

const RecipeSources = styled.div`
  width: 100%;
  border-bottom: 5px solid #000;
  padding-bottom: ${rhythm(1)};
  div.source {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (min-width: 780px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: start;
    }
  }
`;

const formatIngredient = ingredient => {
  return `${ingredient.amount ? ingredient.amount + ' ' : ''}${
    ingredient.name
  }${ingredient.preparation ? ', ' + ingredient.preparation : ''}`;
};

class RecipePostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const { previous, next } = this.props.pageContext;
    const imageSizes = post.frontmatter.featuredImage && [
      post.frontmatter.featuredImage.childImageSharp.recipe1.src,
      post.frontmatter.featuredImage.childImageSharp.recipe2.src,
      post.frontmatter.featuredImage.childImageSharp.recipe3.src,
    ];
    const diets = post.frontmatter.recipe.diet;


    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        vocab="http://schema.org/"
        typeof="Recipe"
      >
        <SEO
          title={post.frontmatter.title}
          description={post.excerpt}
          recipe={post.frontmatter.recipe}
          equipment={post.frontmatter.equipment}
          images={post.frontmatter.featuredImage && imageSizes}
          slug={post.fields.slug}
          date={post.frontmatter.date}
        />
        <h1
          property="name"
          style={{
            marginTop: rhythm(1),
            marginRight: rhythm(1 / 2),
            marginLeft: rhythm(1 / 2),
            textAlign: `center`,
            fontSize: `40px`,
            marginBottom: rhythm(1),
          }}
        >
          {post.frontmatter.title}
        </h1>
        {post.frontmatter.featuredImage && (
          <Img
            property="image"
            fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
            style={{
              width: `100%`,
            }}
          />
        )}
        <p
          style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
            marginTop: rhythm(0.5),
            marginLeft: rhythm(1 / 2),
            marginRight: rhythm(1 / 2),
          }}
          property="datePublished"
        >
          {post.frontmatter.date}
        </p>
        <RecipePost
          dangerouslySetInnerHTML={{ __html: post.html }}
          property="description"
        />

        <RecipeOverview>
          {post.frontmatter.recipe.recipeYield && (
            <li title="Servings" property="recipeYield">
              <FaUtensils />
              <span>
                {post.frontmatter.recipe.recipeYield}{' '}
                {Pluralize('serving', post.frontmatter.recipe.recipeYield)}
              </span>
            </li>
          )}
          {post.frontmatter.recipe.prepTime && (
            <li
              title="Prep Time"
              property="prepTime"
              content={
                'PT' +
                (post.frontmatter.recipe.prepTime.hours
                  ? post.frontmatter.recipe.prepTime.hours + 'H'
                  : '') +
                (post.frontmatter.recipe.prepTime.minutes
                  ? post.frontmatter.recipe.prepTime.minutes + 'M'
                  : '')
              }
            >
              <FaClock />
              <span>
                {post.frontmatter.recipe.prepTime.hours &&
                  post.frontmatter.recipe.prepTime.hours + ' hours&nbsp;'}
                {post.frontmatter.recipe.prepTime.minutes &&
                  post.frontmatter.recipe.prepTime.minutes + ' mins'}
              </span>
            </li>
          )}
          {post.frontmatter.recipe.cookTime && (
            <li
              title="Cook Time"
              property="cookTime"
              content={
                'PT' +
                (post.frontmatter.recipe.cookTime.hours
                  ? post.frontmatter.recipe.cookTime.hours + 'H'
                  : '') +
                (post.frontmatter.recipe.cookTime.minutes
                  ? post.frontmatter.recipe.cookTime.minutes + 'M'
                  : '')
              }
            >
              <FaUserClock />
              <span>
                {post.frontmatter.recipe.cookTime.hours &&
                  post.frontmatter.recipe.cookTime.hours + ' hours&nbsp;'}
                {post.frontmatter.recipe.cookTime.minutes &&
                  post.frontmatter.recipe.cookTime.minutes + ' mins'}
              </span>
            </li>
          )}
          {post.frontmatter.recipe.cookTime && (
            <li title="Total Time">
              <FaStopwatch />
              <span>
                {post.frontmatter.recipe.cookTime.hours &&
                  post.frontmatter.recipe.cookTime.hours +
                    (post.frontmatter.recipe.prepTime &&
                      post.frontmatter.recipe.prepTime.hours) +
                    ' hours&nbsp;'}
                {post.frontmatter.recipe.cookTime.minutes &&
                  post.frontmatter.recipe.cookTime.minutes +
                    (post.frontmatter.recipe.prepTime &&
                      post.frontmatter.recipe.prepTime.minutes) +
                    ' mins'}
              </span>
            </li>
          )}
        </RecipeOverview>
        <RecipeIngredients>
          <h2>Ingredients</h2>
          <ul>
            {post.frontmatter.recipe.recipeIngredients.map(ingredient => {
              return (
                <li key={ingredient.name} property="recipeIngredient">
                  {ingredient.link ? (
                    <Link to={ingredient.link}>
                      {formatIngredient(ingredient)}
                    </Link>
                  ) : (
                    <IngredientLink ingredient={ingredient} />
                  )}
                </li>
              );
            })}
          </ul>
          {diets && (
          <div>
            This recipe can be suitable for the following restricted diets:
            <ul>
              { diets.map(diet => {
                return (<li>{diet.name.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/Diet$/, '')}{diet.requiresModification && ' (with modifications)'}
                </li>);
              })}
            </ul>
          </div>
          )}
        </RecipeIngredients>
        {post.frontmatter.equipment && (
          <RecipeEquipment>
            <h2>Equipment</h2>
            <ul>
              {post.frontmatter.equipment.map((equipment, idx) => {
                return (
                  <li key={idx} itemProp="tool">
                    <EquipmentLink equipment={equipment} />
                  </li>
                );
              })}
            </ul>
          </RecipeEquipment>
        )}
        <RecipeInstructions>
          <h2>Instructions</h2>
          <ol>
            {post.frontmatter.recipe.recipeInstructions.map(
              (instruction, idx) => {
                return (
                  <li
                    key={idx}
                    property="itemListElement"
                    typeof="recipeInstructions"
                  >
                    <a name={'step' + idx} />
                    {instruction.name && <label>{instruction.name}</label>}
                    {instruction.description}
                    {instruction.image && (
                      <Img fluid={instruction.image.childImageSharp.sizes} />
                    )}
                  </li>
                );
              }
            )}
          </ol>
        </RecipeInstructions>
        {post.frontmatter.sources && (
          <RecipeSources>
            <h4>This recipe was adapted from:</h4>
            <div className="source">
              {post.frontmatter.sources.map((source, idx) => {
                return (
                  <SourceLink
                    key={idx}
                    chef={source.chef}
                    dish={source.dish}
                    link={source.link}
                    publication={source.publication}
                  ></SourceLink>
                );
              })}
            </div>
          </RecipeSources>
        )}
        <SimilarRecipes
          tags={post.frontmatter.tags}
          keywords={post.frontmatter.recipe.keywords}
          cuisine={post.frontmatter.recipe.recipeCuisine}
          category={post.frontmatter.recipe.recipeCategory}
          currentSlug={post.fields.slug}
        />
        <Bio />

        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
            margin: rhythm(0.5),
            width: `100%`,
          }}
        >
          <li>
            {previous && (
              <Link
                to={'/' + previous.fields.path + previous.fields.slug}
                rel="prev"
              >
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={'/' + next.fields.path + next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    );
  }
}

export default RecipePostTemplate;

export const pageQuery = graphql`
  query RecipePostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        tags
        date(formatString: "MMMM DD, YYYY")
        sources {
          dish
          link
          chef
          publication
        }
        recipe {
          diet {
            name
            requiresModification
          }
          keywords
          recipeYield
          cookingMethod
          recipeCategory
          recipeCuisine
          prepTime {
            minutes
          }
          cookTime {
            minutes
          }
          recipeIngredients {
            name
            amount
            preparation
            link
          }
          recipeInstructions {
            name
            description
            image {
              childImageSharp {
                sizes(maxWidth: 885) {
                  ...GatsbyImageSharpSizes
                  src
                }
              }
            }
          }
        }
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
            recipe1: fixed(width: 600, height: 600) {
              ...GatsbyImageSharpFixed
            }
            recipe2: fixed(width: 800, height: 600) {
              ...GatsbyImageSharpFixed
            }
            recipe3: fixed(width: 1600, height: 900) {
              ...GatsbyImageSharpFixed
            }
            fixed(width: 600, height: 400) {
              base64
              src
              srcSet
              srcWebp
              srcSetWebp
              originalName
            }
          }
        }
        equipment
      }
    }
  }
`;
